<template>
  <div class="h5bg">


    <div class="inpage" v-if="!stuInfo.id">
      <div>

        <div style="height:38vw;"></div>

        <div class="logo">
          <img :src="logo" v-if="logo" />
        </div>

        <div class="title">南京技师学院-就业信息登记</div>
        <div style="height:6vw;"></div>
        <div class="inputname">姓名</div>
        <div class="inputxt1">
          <input class="inputitem" v-model="stuInfo.stu_name" placeholder="请输入姓名" />
        </div>

        <div style="height:2vw;"></div>
        <div class="inputname">身份证号</div>
        <div class="inputxt2">
          <input class="inputitem" v-model="stuInfo.identity_card" placeholder="请输入身份证号" />
        </div>
        <div style="height:10vw;"></div>
        <div style=" width: 65%;margin: 0 auto;">
          <!-- 截止登记注释开始====== -->
          <!-- <el-button type="primary" :loading="loading" style="width: 100%; margin-bottom: 50px;">登记已截止</el-button> -->
          <!-- 截止登记注释结束======-->
          <!-- @click="searchStu" -->

          <!-- 开始登记注释开始====== -->
          <el-button type="primary" @click="searchStu" :loading="loading"
            style="width: 100%; margin-bottom: 50px;">开始登记</el-button>
          <!-- 开始登记注释结束====== -->
        </div>


        <!-- <el-form ref="form1" :model="enterInfo" label-position="top" label-width="180px">
          <el-form-item label="姓名" style="margin-bottom: 0;" required>
            <el-input v-model="stuInfo.stu_name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" style="margin-bottom: 0;" required>
            <el-input v-model="stuInfo.identity_card" placeholder="请输入身份证号"></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" @click="searchStu" :loading="loading"
            style="width: 100%; margin-top: 50px; margin-bottom: 50px;">开始登记</el-button>
        </div>
        -->
      </div>

    </div>
    <div style="height: 10px;"></div>
    <div style="padding:20px;background-color: #fff; margin:0 10px; border-radius: 6px;">





      <div v-if="stuInfo.id">

        <el-form ref="form11" :model="enterInfo" :inline="true" label-position="left" label-width="100px">




          <el-row>
            <el-col :span="24">
              <el-form-item label="姓名" style="margin-bottom: 0;" required>
                {{ stuInfo.stu_name }}
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item inline label="身份证号" style="margin-bottom: 0;" required>
                {{ stuInfo.identity_card }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item inline label="专业" style="margin-bottom: 0;" required>
                {{ stuInfo.zhuanye_alias }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item inline label="班级" style="margin-bottom: 0;" required>
                {{ stuInfo.class_name }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-form ref="form" :model="enterInfo" label-position="top" label-width="180px">
          <div style="position: relative;">
            <div style="position: absolute; width: 100%; height: 100%; top: 0; z-index: 2;" v-if="enterInfo.id"></div>

            <el-form-item label="毕业去向" style="margin-bottom: 0;" required :inline="false">
              <el-select v-model="enterInfo.byqx" placeholder="请选择毕业去向" style="width:100%">
                <el-option label="就业协议、实习协议形式就业" value="就业协议、实习协议形式就业"></el-option>
                <el-option label="签劳动合同形式就业" value="签劳动合同形式就业"></el-option>
                <el-option label="升学" value="升学"></el-option>
                <el-option label="应征义务兵" value="应征义务兵"></el-option>
                <el-option label="出国、出境" value="出国、出境"></el-option>
                <el-option label="自主创业" value="自主创业"></el-option>
                <el-option label="自由职业" value="自由职业"></el-option>
                <el-option label="国家基层项目" value="国家基层项目"></el-option>
                <el-option label="不就业拟升学" value="不就业拟升学"></el-option>
                <el-option label="其他暂不就业" value="其他暂不就业"></el-option>
              </el-select>

            </el-form-item>


            <el-form-item v-if="enterInfo.byqx != '应征义务兵' && enterInfo.byqx != '不就业拟升学' && enterInfo.byqx != '其他暂不就业'"
              label="企业/单位名称" style="margin-bottom: 0;position: relative;" required>
              <el-input v-model="enterInfo.firm_name" readonly placeholder="请输入企业全称"></el-input>
              <div class="inpage" @click.stop="showChouti"
                style="background-image:none; background-color: rgba(0, 0, 0, 0);"></div>
            </el-form-item>



            <div
              v-if="enterInfo.byqx != '升学' && enterInfo.byqx != '应征义务兵' && enterInfo.byqx != '不就业拟升学' && enterInfo.byqx != '其他暂不就业'">

              <el-form-item label="单位所在地" style="margin-bottom: 0;" required>

                <el-cascader v-model="enterInfo.dwszd" style="width:100%;" @change="selectCity" :props="treeProps"
                  clearable :options="areas" placeholder="请选择地区">
                </el-cascader>



              </el-form-item>

              <el-form-item label="工作职位类别" style="margin-bottom: 0;" required>
                <el-select v-model="enterInfo.jobtype" placeholder="请选择工作职位类别" style="width:100%">
                  <el-option label="工程技术人员" value="工程技术人员"></el-option>
                  <el-option label="商业和服务业人员" value="商业和服务业人员"></el-option>
                  <el-option label="科学研究人员" value="科学研究人员"></el-option>
                  <el-option label="办事人员和有关人员" value="办事人员和有关人员"></el-option>
                  <el-option label="军人" value="军人"></el-option>
                  <el-option label="文学艺术工作人员" value="文学艺术工作人员"></el-option>
                  <el-option label="经济业务人员" value="经济业务人员"></el-option>
                  <el-option label="体育工作人员" value="体育工作人员"></el-option>
                  <el-option label="生产和运输设备操作人员" value="生产和运输设备操作人员"></el-option>
                  <el-option label="农林牧渔业技术人员" value="农林牧渔业技术人员"></el-option>
                  <el-option label="教学人员" value="教学人员"></el-option>
                  <el-option label="卫生专业技术人员" value="卫生专业技术人员"></el-option>
                  <el-option label="新闻出版和文化工作人员" value="新闻出版和文化工作人员"></el-option>
                  <el-option label="其他专业技术人员" value="其他专业技术人员"></el-option>
                  <el-option label="其他人员" value="其他人员"></el-option>
                </el-select>

              </el-form-item>

              <el-form-item label="专业是否对口" style="margin-bottom: 0;" required>
                <el-radio-group v-model="enterInfo.duikou">
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>

                </el-radio-group>
              </el-form-item>
              <el-form-item label="是否有劳动合同" style="margin-bottom: 0;" required>
                <el-radio-group v-model="enterInfo.hetong">
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="是否有三险五险" style="margin-bottom: 0;" required>
                <el-radio-group v-model="enterInfo.shebao">
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
              </el-form-item>


              <el-form-item label="是否有公积金" style="margin-bottom: 0;" required>
                <el-radio-group v-model="enterInfo.gongjijin">
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
              </el-form-item>




              <el-form-item label="就业是否满意" style="margin-bottom: 0;" required>
                <el-radio-group v-model="enterInfo.manyidu">
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="税后月工资(元/月)" style="margin-bottom: 0;" required>
                <el-input type="number" v-model="enterInfo.salary" placeholder="请输入数字"></el-input>
              </el-form-item>


              <el-form-item label="企业联系人" style="margin-bottom: 0;" required>
                <el-input v-model="enterInfo.firm_principal" placeholder="请输入企业联系人"></el-input>
              </el-form-item>





              <el-form-item label="企业联系人电话" style="margin-bottom: 0;" required>
                <el-input type="number" v-model="enterInfo.tel" placeholder="请输入企业联系人电话,请输入数字"></el-input>
              </el-form-item>

              <el-form-item label="单位属于" style="margin-bottom: 0;" required>
                <el-select v-model="enterInfo.enter_type" placeholder="请选择单位属于" style="width:100%">
                  <el-option label="国防军工" value="国防军工"></el-option>
                  <el-option label="世界500强" value="世界500强"></el-option>
                  <el-option label="民营500强" value="民营500强"></el-option>
                  <el-option label="上市公司" value="上市公司"></el-option>
                  <el-option label="教育培训" value="教育培训"></el-option>
                  <el-option label="基础教育" value="基础教育"></el-option>
                  <el-option label="其他" value="其他"></el-option>

                </el-select>
              </el-form-item>
            </div>
          </div>

          <div v-if="enterInfo.id"
            style="margin-left:0px;margin-bottom:8px;font-size:14px;line-height:20px;color:orangered;padding-top:10px">
            以上信息填写后不可更改，如需更改请联系班主任
          </div>

          <el-form-item label="上传就业材料" required v-if="enterInfo.byqx != '不就业拟升学' && enterInfo.byqx != '其他暂不就业'">
            <div style="margin-left:0px;margin-bottom:8px;font-size:14px;line-height:20px;color:orangered">
              <div v-if="enterInfo.byqx == '就业协议、实习协议形式就业'">
                请上传就业证明照片，就业证明要求单位公章，一个证明上人数不超过5人，有企业联系人和联系电话。技工学校毕业生采集材料如确实有困难，实在收不到证明材料和劳动合同的，可以用原实习协议代替
              </div>
              <div v-else-if="enterInfo.byqx == '签劳动合同形式就业'">
                请上传劳动合同原件照片，上传劳动合同的首页和盖章页
              </div>
              <div v-else-if="enterInfo.byqx == '升学'">
                请上传录取通知书照片
              </div>
              <div v-else-if="enterInfo.byqx == '应征义务兵'">
                请上传入伍通知书
              </div>
              <div v-else-if="enterInfo.byqx == '出国、出境'">
                出国相关盖章材料
              </div>
              <div v-else-if="enterInfo.byqx == '自主创业'">
                营业执照，法人名为学生本人
              </div>
              <div v-else-if="enterInfo.byqx == '签劳动合同形式就业'">
                营业执照，法人名为学生本人
              </div>
              <div v-else>
                请上传就业证明或其他证明文件。
              </div>


            </div>
            <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
              v-for="(img, idx) in enterInfo.files" :key="idx">
              <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                :preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>

              <div class="imgremove" @click="removeImg(idx)"><i class="el-icon-remove"></i></div>
            </div>

            <div style="display:inline-block;vertical-align: top;">

              <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadOss"
                :on-success="uploadFiles" :show-file-list="false" name="image" accept="image/png, image/jpeg">
                <div class="imgaddbtn">
                  ＋
                </div>
              </el-upload>
            </div>
          </el-form-item>

          <div>
            <el-button v-if="!loading" type="primary" @click="saveForm" :loading="loading"
              style="width: 100%; margin-top: 50px; margin-bottom: 50px;">提交登记</el-button>
          </div>

        </el-form>
      </div>





    </div>

    <div style="height:20px"></div>


    <el-drawer title="请输入企业全称" size="80%" :visible.sync="drawer" direction="rtl" @close="drawer = false">
      <div style="padding:0 20px">
        <el-input placeholder="请输入请输入企业全称" :autofocus="true" v-model="keyword" class="input-with-select">

          <el-button type="primary" slot="append" icon="el-icon-search" @click="searchEnter"></el-button>
        </el-input>
      </div>

      <div>
        <div v-for="(ent, i) in EnterList" :key="i" class="enteritem" @click="setEnter(ent)">
          <div> {{ ent.name }}</div>
          <div> {{ ent.creditCode }}</div>
        </div>

      </div>

    </el-drawer>


  </div>
</template>

<script>
import area from "../../utils/arae_value.js"

export default {
  data() {
    return {
      openid: "asfljasfjdasldjfasklf",
      pmid: 0,
      loading: false,
      drawer: false,
      keyword: "",
      stuInfo: {
        stu_name: "",
        identity_card: "",
      },
      enterInfo: {
        byqx: '',
        firm_name: "",
        firm_num: "",
        address: "",
        firm_principal: "",
        tel: "",
        dwszd: "",
        dwszd_name: "",
        jobtype: "",
        duikou: "是",
        hetong: "是",
        shebao: "",
        gongjijin: "",
        manyidu: "是",
        salary: "",
        enter_type: "",
        files: []
      },
      zphid: 0,
      job_info_list: [],
      job: { "post": "", "num": 3, boy: "", girl: "", "specialty": "", "culture": "", "foreignLanguage": "", "computer": "", jintie: "", salary: "" },
      fileList: [],
      EnterList: [],
      treeProps: {
        children: 'children',
        label: 'label',
        value: 'value',
        emitPath: false,
      },
      areas: [],
      saveok: false,
      logo: ""

    };
  },
  mounted() {

    document.title = "南京技师学院-就业信息登记表"
    this.pmid = 223
    window.pmid = 223
    // this.searchStu()
    this.getLogo()
    for (let p of area) {
      if (p.children) {
        for (let c of p.children) {
          delete c.children
        }
      }

    }
    this.areas = area



  },
  methods: {
    getLogo() {
      this.$http.post("/api/sch_config_one", { ktype: "logo", pmid: this.pmid }).then(res => {
        this.logo = res.data.kvalue
      })
    },
    selectCity(e) {

      for (let a of area) {
        if (a.children) {
          for (let b of a.children) {
            if (b.value == this.enterInfo.dwszd) {
              this.enterInfo.dwszd_name = a.label + "," + b.label
              console.log(this.enterInfo.dwszd_name)
              return
            }

          }
        }
      }

    },
    searchStu() {
      if (this.stuInfo.stu_name && this.stuInfo.identity_card) {
        this.$http.post("/api/stu_byidcard", { pmid: this.pmid, stu_name: this.stuInfo.stu_name, identity_card: this.stuInfo.identity_card }).then(res => {
          if (res.data) {
            let identity_card = this.stuInfo.identity_card
            res.data.identity_card = identity_card
            this.stuInfo = res.data
            this.getJobInfo()
          } else {
            this.$message({
              message: "未找到学生信息，请检查输入信息是否正确！",
              type: 'error',
              customClass: "toast",
              duration: 2000
            });

          }


        })
      }
    },
    getJobInfo() {
      this.$http.post("/api/byqx_self_one", { stu_id: this.stuInfo.id }).then(res => {
        if (res.data) {
          res.data.files = JSON.parse(res.data.files)
          this.enterInfo = res.data
          this.saveok = true
        }
      })
    },

    checkTyc() {
      if (this.enterInfo.firm_name.trim()) {
        this.$http.post("/api/tyc_search", { enter_name: this.enterInfo.firm_name }).then(res => {
          if (res.data) {
            this.enterInfo.firm_name = res.data.name
            this.enterInfo.address = res.data.regLocation
            this.enterInfo.firm_num = res.data.creditCode
          }
        })
      }
    },
    showChouti(e) {

      this.drawer = true
    },
    searchEnter() {
      if (this.keyword.trim()) {
        this.$http.post("/api/tyc_search_list", { enter_name: this.keyword.trim() }).then(res => {
          if (res.data) {
            this.EnterList = res.data
          }
        })
      }
    },
    setEnter(e) {
      this.enterInfo.firm_name = e.name
      this.checkTyc()
      this.drawer = false
    },
    uploadFiles(e) {
      this.enterInfo.files.push(e)
    },
    removeImg(idx) {
      this.enterInfo.files.splice(idx, 1)
    },

    saveForm() {


      if (this.enterInfo.byqx != '升学' && this.enterInfo.byqx != '应征义务兵' && this.enterInfo.byqx != '不就业拟升学' && this.enterInfo.byqx != '其他暂不就业') {
        if (!this.enterInfo.dwszd) {
          this.$message({
            message: "请选择单位所在地",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }
        if (!this.enterInfo.jobtype.trim()) {
          this.$message({
            message: "请选择职位类别",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }
        if (!this.enterInfo.duikou) {
          this.$message({
            message: "请选择专业是否对口",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }
        if (!this.enterInfo.shebao) {
          this.$message({
            message: "请选择是否有三线五险",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }
        if (!this.enterInfo.gongjijin) {
          this.$message({
            message: "请选择是否有公积金",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }
        if (!this.enterInfo.manyidu) {
          this.$message({
            message: "请选择就业满意度",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }

        if (!this.enterInfo.salary) {
          this.$message({
            message: "请输入月薪",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }

        if (!this.enterInfo.firm_principal) {
          this.$message({
            message: "请输入企业联系人",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }


        if (!this.enterInfo.tel) {
          this.$message({
            message: "请输入企业联系人电话",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }
        if (!this.enterInfo.enter_type) {
          this.$message({
            message: "请选择企业类型",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }



      }


      //检查岗位有没有填写

      if (this.enterInfo.files.length == 0) {
        if (this.enterInfo.byqx == '不就业拟升学' || this.enterInfo.byqx == '其他暂不就业') {
          this.enterInfo.files=[]
        } else {
          this.$message({
            message: "请上传就业证明或者劳动合同的首页和盖章页或其他证明文件",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }
      }
      this.loading = true
      let data = { ...this.enterInfo }
      data.stu_id = this.stuInfo.id
      this.$http.post("/api/t_sch_byqx_self_edit", data).then(res => {
        this.$message({
          message: "信息登记成功，感谢您的参与！",
          type: 'success',
          customClass: "toast",
          duration: 5000
        });
        this.loading = false
        this.saveok = true
        this.enterInfo.id = 999999


      })


    }

  }

};
</script>

<style scoped>
.h5bg {
  background-image: url(../../../public/img/xiantiao.jpg);
  background-size: 100% 100%;
  height: 100vh;
  overflow: auto;
  position: relative;

}

.inpage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  background-image: url(../../../public/img/h5login.jpg);
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
  background-color: #f4f7fc;

}

.logo {
  height: 25vw;
  width: 25vw;
  background-color: #ffffff;
  margin: 0 auto;
  box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.logo img {
  width: 100%;
  height: 100%;
}

.title {
  color: #24297a;
  font-weight: bolder;
  text-align: center;
  padding-top: 5vw;


}

.inputname {
  width: 62%;
  margin: 0 auto;
  padding: 10px 0;
  color: #24297a;
}

.inputxt1 {
  width: 65%;
  margin: 0 auto;
  height: 10vw;
  background-image: url(../../../public/img/login_txt1.png);
  background-size: 100% 100%;
}

.inputxt2 {
  width: 65%;
  margin: 0 auto;
  height: 10vw;
  background-image: url(../../../public/img/login_txt2.png);
  background-size: 100% 100%;
}

.inputitem {
  width: 75%;
  margin-left: 13vw;
  height: 100%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: none;
}

.remove {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
}

.remove img {
  width: 100%;
  height: 100%;
}

.add {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.add img {
  width: 100%;
  height: 100%;
}
</style>

<style>
.el-form--label-top .el-form-item__label {
  margin-bottom: 0;
  padding: 0;
}

.toast {
  width: 80%;
  min-width: 10% !important;
  max-width: 350px !important;
}

.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}

.enteritem {
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
}

.enteritem:active {
  background-color: #eee;
}
</style>